body {
  font-family: 'Roboto', sans-serif;

  --chakra-shadows-outline: 0 0 0 3px #4FD1C5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link.active {
  color: var(--chakra-colors-blue-500);
}

.table-without-scroll::-webkit-scrollbar {
  display: none;
}

.table-without-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: red;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-blue-300);
  cursor: pointer;
  border-radius: 3px;
  opacity: .5;
}

.not-overflowed {
  overflow: auto !important;
}


.array-switcher {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  background: var(--chakra-colors-gray-200);
  outline: none;
  border-radius: 4px;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.array-switcher:hover {
  opacity: 1;
}

.array-switcher::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  border-radius: 4px;
  height: 18px;
  background: var(--chakra-colors-blue-500);
  cursor: pointer;
}

.array-switcher::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: var(--chakra-colors-blue-500);
  cursor: pointer;
}